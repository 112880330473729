import { render, staticRenderFns } from "./BaseStaticBlock.vue?vue&type=template&id=0e177518&"
import script from "./BaseStaticBlock.vue?vue&type=script&lang=js&"
export * from "./BaseStaticBlock.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseHtmlContent: require('/home/gitlab-runner/builds/KakjLXLD/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/BaseHtmlContent.vue').default})
